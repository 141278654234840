import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Select,
    MenuItem,
    DialogActions,
    Button,
    Card,
} from '@mui/material';
import './UserDialog.scss';
import { EMAIL_REGEX } from '@stephenprn/typescript-common/lib/utils/string';
import React, { useState } from 'react';
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../../../../constants';
import { IFormDialogProps } from '../../../../interfaces/props/add-item-dialog';
import { handleErrors } from '../../../../utils/http.utils';
import { AUTH_REGISTER_ENDPOINT, getUserEditEndpoint } from '../../../../api-endpoints';
import { User } from '../../../../interfaces/user';
import AddIcon from '@mui/icons-material/Add';
import { UserRole } from '../../../../enums/user-role';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';

export const UserDialog: React.FC<IFormDialogProps<User>> = ({
    showed,
    setShowed,
    setLastItemAddedUuid,
    initialData,
    setDetail,
}: IFormDialogProps<User>) => {
    // form fields

    const [userEmail, setUserEmail] = useState<string>(initialData?.email || '');
    const [userName, setUserName] = useState<string>(initialData?.username || '');
    const [userPassword, setUsePassword] = useState<string>('');
    const [userRole, setUserRole] = useState<UserRole>(initialData?.role ? UserRole[initialData.role] : UserRole.ADMIN);
    const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);

    // dialog state

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<GenericError>(null);

    const upsertUser = async () => {
        if (loading) {
            return;
        }

        setLoading(true);

        if (!EMAIL_REGEX.test(userEmail)) {
            setError('Invalid email');
            setLoading(false);
            return;
        }

        if (userName.length === 0) {
            setError('Enter an username');
            setLoading(false);
            return;
        }

        if (
            (!(initialData && !userPassword) && userPassword.length < PASSWORD_MIN_LENGTH) ||
            userPassword.length > PASSWORD_MAX_LENGTH
        ) {
            setError(`Password's length must be between ${PASSWORD_MIN_LENGTH} and ${PASSWORD_MAX_LENGTH} characters`);
            setLoading(false);
            return;
        }

        let response: any;

        try {
            let url: string;

            if (initialData) {
                url = getUserEditEndpoint(initialData.uuid);
            } else {
                url = AUTH_REGISTER_ENDPOINT;
            }

            response = await axios.post(
                url,
                {
                    username: userName,
                    email: userEmail,
                    role: userRole,
                    ...(userPassword ? { password: userPassword } : {}),
                    ...(profilePictureFile ? { profilePictureFile } : {}),
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setError(err.response?.data);
            setLoading(false);
            return;
        }

        setLastItemAddedUuid && setLastItemAddedUuid(response.data.uuid);
        setDetail && setDetail(response.data);

        setUserEmail(initialData ? userEmail : '');
        setUserName(initialData ? userName : '');
        setUsePassword('');
        setUserRole(initialData ? userRole : UserRole.ADMIN);
        setError(null);
        setLoading(false);
        setShowed(false);
    };

    return (
        <Dialog open={showed} onClose={() => setShowed(false)}>
            <form>
                <DialogTitle>Register a user</DialogTitle>
                <DialogContent className="form">
                    <ErrorAlert error={error} title="Upsert user" />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="user-email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="user-name"
                        label="Username"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="user-password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={userPassword}
                        onChange={(e) => setUsePassword(e.target.value)}
                    />
                    <Select
                        margin="dense"
                        id="user-role"
                        label="Role"
                        fullWidth
                        variant="outlined"
                        value={userRole}
                        onChange={(e) => {
                            const role = UserRole[e.target.value as keyof typeof UserRole];
                            setUserRole(role);
                        }}
                    >
                        {Object.values(UserRole).map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>
                    <Card className="form-field-card">
                        <h3>Profile picture</h3>
                        <label htmlFor="profile-picture-file">Upload profile picture</label>
                        <input
                            type="file"
                            id="profile-picture-file"
                            accept="image/*"
                            onChange={(e: any) => setProfilePictureFile(e.target.files[0])}
                        />
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            setShowed(false);
                            e.preventDefault();
                        }}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                            upsertUser();
                            e.preventDefault();
                        }}
                        disabled={loading}
                    >
                        <AddIcon />
                        Register
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
