import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Card,
    Fab,
    CardContent,
    Autocomplete,
    Button,
    CircularProgress,
} from '@mui/material';
import './CityUpsert.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Interest } from '../../../../enums/interest';
import { City as ICity } from '../../../../interfaces/city';
import { Department } from '../../../../interfaces/department';
import { handleErrors } from '../../../../utils/http.utils';
import React from 'react';
import {
    CITY_ADD_ENDPOINT,
    DEPARTMENT_LIST_ENDPOINT,
    getCityDetailEndpoint,
    getCityEditEndpoint,
} from '../../../../api-endpoints';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { ResultWithNbr } from '@stephenprn/typescript-common/lib/interfaces/pagination';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '../../../../utils/editor/editor.utils';

const ReactEditorJSActivities = createReactEditorJS();
const ReactEditorJSFunFact = createReactEditorJS();

export const CityUpsert: React.FC = () => {
    const { cityUuid } = useParams();
    const navigate = useNavigate();
    const editorCoreActivities = useRef<any>(null);
    const editorCoreFunFact = useRef<any>(null);

    const handleInitializeEditorActivities = useCallback((editorInstance: any) => {
        editorCoreActivities.current = editorInstance;
    }, []);

    const handleInitializeEditorFunFact = useCallback((editorInstance: any) => {
        editorCoreFunFact.current = editorInstance;
    }, []);

    // city state

    const [cityDetail, setCityDetail] = useState<ICity>({
        name: '',
        insee: '',
        illustrationLargeUrl: null,
        illustrationSmallUrl: null,
        interests: [],
        isDestination: false,
        starredIndex: 0,
        locode: '',
        creationDate: '',
        updateDate: '',
        uuid: '',
        slug: '',
        funFact: {},
        activities: {},
    });
    const [illustrationSmallFile, setIllustrationSmallFile] = useState<File | null>(null);
    const [illustrationLargeFile, setIllustrationLargeFile] = useState<File | null>(null);
    const [deleteIllustrationSmall, setDeleteIllustrationSmall] = useState<boolean>(false);
    const [deleteIllustrationLarge, setDeleteIllustrationLarge] = useState<boolean>(false);
    const [department, setDepartment] = useState<Department | null>(null);

    const [cityDetailLoading, setCityDetailLoading] = useState<boolean>(false);
    const [cityDetailError, setCityDetailError] = useState<GenericError>(null);

    const loadCityDetail = async () => {
        if (cityDetailLoading || !cityUuid) {
            return;
        }

        setCityDetailLoading(true);
        setCityDetailError(null);

        let response: any;

        try {
            response = await axios.get<ICity>(getCityDetailEndpoint(cityUuid));
            setCityDetail(response.data);
            setDepartment(response.data.department);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setCityDetailError(err.response?.data);
            setCityDetailLoading(false);
            return;
        }

        setCityDetailLoading(false);
    };

    // departments state

    const [departments, setDepartments] = useState<Department[]>([]);
    const [departmentsError, setDepartmentsError] = useState<GenericError>(null);
    const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(false);

    const loadDepartments = async () => {
        if (departmentsLoading) {
            return;
        }

        setDepartmentsLoading(true);
        setDepartmentsError(null);

        let response: any;

        try {
            response = await axios.get<ResultWithNbr<Department>>(DEPARTMENT_LIST_ENDPOINT, {
                params: {
                    pageNbr: 0,
                    nbrResults: 1000,
                },
            });
            setDepartments(response.data.data);

            if (response.data.data.length === 0) {
                setDepartmentsError('No departments found, please add one before adding cities');
            }
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setDepartmentsError(err.response?.data);
        }

        setDepartmentsLoading(false);
    };

    useEffect(() => {
        loadCityDetail();
        loadDepartments();
    }, []);

    // state

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<GenericError>(null);

    const addCity = async () => {
        if (loading) {
            return;
        }

        // fields validation

        if (!cityDetail.name) {
            setError('Name is required');
            return;
        }

        if (!cityDetail.insee) {
            setError('INSEE is required');
            return;
        }

        if (!department) {
            setError('Department is required');
            return;
        }

        if (!cityUuid && cityDetail.isDestination && !illustrationSmallFile && !illustrationLargeFile) {
            setError('At least one illustration is required for destination');
            return;
        }

        setLoading(true);
        setError(null);

        let response: any;

        const activities = await editorCoreActivities.current.save();
        const funFact = await editorCoreFunFact.current.save();

        try {
            let endpoint: string;
            let starredIndex: number | null = null;

            if (cityUuid) {
                endpoint = getCityEditEndpoint(cityUuid);
                starredIndex = cityDetail.starredIndex;
            } else {
                endpoint = CITY_ADD_ENDPOINT;
            }

            response = await axios.post(
                endpoint,
                {
                    insee: cityDetail.insee,
                    name: cityDetail.name,
                    interests: cityDetail.interests.join(','),
                    departmentUuid: department.uuid,
                    isDestination: cityDetail.isDestination,
                    starredIndex,
                    activities: JSON.stringify(activities),
                    funFact: JSON.stringify(funFact),
                    ...(illustrationSmallFile
                        ? {
                              illustrationSmallFile,
                          }
                        : {}),
                    ...(illustrationLargeFile
                        ? {
                              illustrationLargeFile,
                          }
                        : {}),
                    ...(cityUuid ? { deleteIllustrationSmall, deleteIllustrationLarge } : {}),
                    ...(cityUuid ? { locode: cityDetail.locode } : {}),
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );

            navigate(`/admin/cities/detail/${response.data.uuid}`);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setError(err.response?.data);
            setLoading(false);
            return;
        }

        setLoading(false);
    };

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <ErrorAlert error={error} title="Save city" />
                <ErrorAlert error={departmentsError} title="Departments loading" />
                <ErrorAlert error={cityDetailError} title="Get city details" />

                <div className="admin-headed-content-header">
                    <h1>{cityUuid ? 'Edit' : 'Add'} a city</h1>
                    {cityUuid ? (
                        <div className="admin-headed-content-header-actions">
                            <Button
                                variant="contained"
                                onClick={() => navigate(`/admin/cities/detail/${cityDetail.uuid}`)}
                            >
                                Go back to details
                            </Button>
                        </div>
                    ) : null}
                </div>
                <div className="admin-headed-content-content">
                    <Card>
                        <CardContent>
                            <form>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={cityDetail.name}
                                    disabled={!!cityUuid}
                                    onChange={(e) =>
                                        setCityDetail({
                                            ...cityDetail,
                                            name: e.target.value,
                                        })
                                    }
                                />
                                <a
                                    href="https://public.opendatasoft.com/explore/dataset/correspondance-code-insee-code-postal/table/?flg=fr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Rechercher un insee
                                </a>
                                <TextField
                                    margin="dense"
                                    id="insee"
                                    label="INSEE"
                                    type="number"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                                    fullWidth
                                    variant="outlined"
                                    value={cityDetail.insee}
                                    disabled={!!cityUuid}
                                    onChange={(e) => setCityDetail({ ...cityDetail, insee: e.target.value })}
                                />
                                {cityUuid ? (
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="locode"
                                        label="Locode"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={cityDetail.locode}
                                        onChange={(e) =>
                                            setCityDetail({
                                                ...cityDetail,
                                                locode: e.target.value,
                                            })
                                        }
                                    />
                                ) : null}
                                {departments ? (
                                    <Autocomplete<Department>
                                        options={departments}
                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                        getOptionLabel={(department: Department) =>
                                            `${department.name} (${department.number})`
                                        }
                                        renderInput={(params) => <TextField {...params} label="Department" />}
                                        id="department"
                                        fullWidth
                                        value={department}
                                        placeholder="Department"
                                        onChange={(_, value) => {
                                            setDepartment(value);
                                        }}
                                    />
                                ) : null}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cityDetail.isDestination}
                                            onChange={(e) =>
                                                setCityDetail({
                                                    ...cityDetail,
                                                    isDestination: e.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Destination (next fields are required if checked)"
                                />

                                <label>Activities</label>
                                {!cityUuid || (cityUuid && !cityDetailLoading) ? (
                                    <Card className="form-field-card">
                                        <ReactEditorJSActivities
                                            holder="holder-activities"
                                            onInitialize={handleInitializeEditorActivities}
                                            tools={EDITOR_JS_TOOLS}
                                            defaultValue={cityUuid ? cityDetail.activities : undefined}
                                        >
                                            <div id="holder-activities" />
                                        </ReactEditorJSActivities>
                                    </Card>
                                ) : (
                                    <CircularProgress />
                                )}

                                <label>Fun facts</label>
                                {!cityUuid || (cityUuid && !cityDetailLoading) ? (
                                    <Card className="form-field-card">
                                        <ReactEditorJSFunFact
                                            holder="holder-funfact"
                                            onInitialize={handleInitializeEditorFunFact}
                                            tools={EDITOR_JS_TOOLS}
                                            defaultValue={cityUuid ? cityDetail.funFact : undefined}
                                        >
                                            <div id="holder-funfact" />
                                        </ReactEditorJSFunFact>
                                    </Card>
                                ) : (
                                    <CircularProgress />
                                )}

                                <InputLabel id="interests-select-label">Interests</InputLabel>
                                <Select
                                    margin="dense"
                                    id="interests"
                                    labelId="interests-select-label"
                                    fullWidth
                                    variant="outlined"
                                    multiple={true}
                                    value={cityDetail.interests}
                                    placeholder="Interests"
                                    onChange={(e) => {
                                        setCityDetail({
                                            ...cityDetail,
                                            interests: (e.target.value as (keyof typeof Interest)[]).map(
                                                (interest) => Interest[interest],
                                            ),
                                        });
                                    }}
                                >
                                    {Object.values(Interest).map((interest) => (
                                        <MenuItem key={interest} value={interest}>
                                            {interest}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Card className="form-field-card">
                                    <h3>Small illustration</h3>
                                    {cityDetail.illustrationSmallUrl ? (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={deleteIllustrationSmall}
                                                    onChange={(e) => setDeleteIllustrationSmall(e.target.checked)}
                                                />
                                            }
                                            label="Delete existing small illustration"
                                        />
                                    ) : null}
                                    <label htmlFor="illustration-small-file">Upload small illustration</label>
                                    <input
                                        type="file"
                                        id="illustration-small-file"
                                        accept="image/*"
                                        onChange={(e: any) => setIllustrationSmallFile(e.target.files[0])}
                                    />
                                </Card>

                                <Card className="form-field-card">
                                    <h3>Large illustration</h3>
                                    {cityDetail.illustrationLargeUrl ? (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={deleteIllustrationLarge}
                                                    onChange={(e) => setDeleteIllustrationLarge(e.target.checked)}
                                                />
                                            }
                                            label="Delete existing large illustration"
                                        />
                                    ) : null}
                                    <label htmlFor="illustration-large-file">Upload large illustration</label>
                                    <input
                                        type="file"
                                        id="illustration-large-file"
                                        accept="image/*"
                                        onChange={(e: any) => setIllustrationLargeFile(e.target.files[0])}
                                    />
                                </Card>

                                <Fab
                                    className="save-button-fab"
                                    color="primary"
                                    variant="extended"
                                    disabled={loading}
                                    onClick={() => addCity()}
                                >
                                    {cityUuid ? <EditIcon sx={{ mr: 1 }} /> : <AddIcon sx={{ mr: 1 }} />}
                                    Save
                                </Fab>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </BaseLayout>
    );
};
