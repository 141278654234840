import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.scss';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Login } from './pages/login/Login';
import { UserList } from './pages/admin/user/list/UserList';
import { CityList } from './pages/admin/city/list/CityList';
import { CityDetail } from './pages/admin/city/detail/CityDetail';
import { AUTH_ACCESS_TOKEN_STORAGE_KEY } from './constants';
import { DepartmentList } from './pages/admin/department/list/DepartmentList';
import { DepartmentDetail } from './pages/admin/department/detail/DepartmentDetail';
import axios from 'axios';
import { BlogArticleList } from './pages/admin/blog-article/list/BlogArticleList';
import { BlogArticleDetail } from './pages/admin/blog-article/detail/BlogArticleDetail';
import { UserDetail } from './pages/admin/user/detail/UserDetail';
import { CityUpsert } from './pages/admin/city/upsert/CityUpsert';

const rootNode = document.getElementById('root');

if (rootNode == null) {
    throw new Error('No root element');
}

// load auth

const accessToken = localStorage.getItem(AUTH_ACCESS_TOKEN_STORAGE_KEY);

if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Login />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/admin/users',
        element: <UserList />,
    },
    {
        path: '/admin/users/:userUuid',
        element: <UserDetail />,
    },
    {
        path: '/admin/departments',
        element: <DepartmentList />,
    },
    {
        path: '/admin/departments/:departmentUuid',
        element: <DepartmentDetail />,
    },
    {
        path: '/admin/cities',
        element: <CityList />,
    },
    {
        path: '/admin/cities/detail/:cityUuid',
        element: <CityDetail />,
    },
    {
        path: '/admin/cities/edit/:cityUuid',
        element: <CityUpsert />,
    },
    {
        path: '/admin/cities/add',
        element: <CityUpsert />,
    },
    {
        path: '/admin/blog-articles',
        element: <BlogArticleList />,
    },
    {
        path: '/admin/blog-articles/detail',
        element: <BlogArticleDetail />,
    },
    {
        path: '/admin/blog-articles/detail/:blogArticleUuid',
        element: <BlogArticleDetail />,
    },
]);

const root = ReactDOM.createRoot(rootNode);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
