import React from 'react';
import { GenericError } from '../../../../interfaces/error';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface ErrorAlertProps {
    title: string;
    error: GenericError;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, title }: ErrorAlertProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    if (!error) {
        return null;
    }

    let errorMessage: string;
    let errorDetail: string[] | null = null;

    if (typeof error === 'string' || error instanceof String) {
        errorMessage = String(error);
        errorDetail = null;
    } else {
        errorMessage = error.message;

        if (Array.isArray(error.detail)) {
            errorDetail = error.detail;
        } else {
            errorDetail = Object.keys(error.detail).map((key) => `${key}: ${JSON.stringify(error.detail[key])}`);
        }
    }

    return (
        <Alert severity="error">
            <AlertTitle>
                {`${title} error: ${errorMessage}`}
                {errorDetail ? (
                    <IconButton onClick={() => setExpanded((prev) => !prev)}>
                        <ChevronDownIcon />
                    </IconButton>
                ) : null}

                {errorDetail && expanded ? (
                    <div className="alert-details">
                        {errorDetail.map((detail, index) => (
                            <div key={index}>{detail}</div>
                        ))}
                    </div>
                ) : null}
            </AlertTitle>
        </Alert>
    );
};
