import './DepartmentDialog.scss';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useState } from 'react';
import { Department } from '../../../../interfaces/department';
import { IFormDialogProps } from '../../../../interfaces/props/add-item-dialog';
import { handleErrors } from '../../../../utils/http.utils';
import React from 'react';
import { DEPARTMENT_ADD_ENDPOINT, getCityEditEndpoint } from '../../../../api-endpoints';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';

export const DepartmentDialog: React.FC<IFormDialogProps<Department>> = ({
    showed,
    setShowed,
    setLastItemAddedUuid,
    initialData,
    setDetail,
}: IFormDialogProps<Department>) => {
    // form fields

    const [name, setName] = useState<string>(initialData?.name || '');
    const [number, setNumber] = useState<number | null>(initialData?.number || null);

    // dialog state

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<GenericError>(null);

    const addDepartment = async () => {
        if (loading) {
            return;
        }

        // fields validation

        if (!name) {
            setError('Name is required');
            return;
        }

        if (!number) {
            setError('Number is required');
            return;
        }

        setLoading(true);
        setError(null);

        let response: any;

        try {
            let endpoint: string;

            if (initialData) {
                endpoint = getCityEditEndpoint(initialData.uuid);
            } else {
                endpoint = DEPARTMENT_ADD_ENDPOINT;
            }

            response = await axios.post<Department>(endpoint, {
                name,
                number,
            });
            setLastItemAddedUuid && setLastItemAddedUuid(response.data.uuid);
            setDetail && setDetail(response.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setError(err.response?.data);
            setLoading(false);
            return;
        }

        setName(initialData ? name : '');
        setNumber(initialData ? number : null);

        setLoading(false);
        setShowed(false);
    };

    return (
        <Dialog open={showed} onClose={() => setShowed(false)}>
            <DialogTitle>{initialData ? 'Edit' : 'Add'} a department</DialogTitle>
            <DialogContent className="form">
                <ErrorAlert error={error} title="Save department" />

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="number"
                    label="Number"
                    type="text"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    fullWidth
                    multiline={true}
                    variant="outlined"
                    value={number || ''}
                    onChange={(e) => setNumber(Number(e.target.value))}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowed(false)} disabled={loading}>
                    Cancel
                </Button>
                <Button variant="contained" color="success" onClick={() => addDepartment()} disabled={loading}>
                    {initialData ? <EditIcon /> : <AddIcon />}
                    {initialData ? 'Edit' : 'Add'} department
                </Button>
            </DialogActions>
        </Dialog>
    );
};
