import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import './ConfirmDialog.scss';
import React from 'react';

interface ConfirmDialogProps {
    showed: boolean;
    setShowed: (showed: boolean) => void;
    bodyText: string;
    confirmText?: string;
    cancelText?: string;
    confirmAction: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    showed,
    setShowed,
    bodyText,
    confirmText,
    cancelText,
    confirmAction,
}: ConfirmDialogProps) => {
    return (
        <Dialog open={showed} onClose={() => setShowed(false)}>
            <DialogContent>{bodyText}</DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setShowed(false)}>
                    {cancelText ? cancelText : 'Cancel'}
                </Button>
                <Button variant="contained" color="error" type="submit" onClick={confirmAction}>
                    {confirmText ? confirmText : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
