import React, { useState } from 'react';
import { DEPARTMENT_LIST_ENDPOINT } from '../../../../api-endpoints';
import './DepartmentList.scss';
import { DataTable } from '../../_common/DataTable/DataTable';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { GridColDef } from '@mui/x-data-grid';
import { Department } from '../../../../interfaces/department';
import { Button, Link } from '@mui/material';
import LocationCity from '@mui/icons-material/LocationCity';
import { DepartmentDialog } from '../dialog/DepartmentDialog';
import AddIcon from '@mui/icons-material/Add';

export const DepartmentList: React.FC = () => {
    const [addDepartmentDialogShowed, setAddDepartmentDialogShowed] = useState(false);
    const [lastDepartmentAddedUuid, setLastDepartmentAddedUuid] = useState<string | undefined>(undefined);

    const dataColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'number', headerName: 'Number', flex: 1 },
        {
            field: '',
            headerName: 'Detail',
            flex: 1,
            renderCell: (value: any) => (
                <Link href={`/admin/departments/${value.row.uuid}`} component={Button}>
                    <LocationCity />
                    Go to details
                </Link>
            ),
        },
    ];

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <div className="admin-headed-content-header">
                    <h1>Departments list</h1>
                    <Button variant="contained" onClick={() => setAddDepartmentDialogShowed(true)}>
                        <AddIcon />
                        Add department
                    </Button>
                    <DepartmentDialog
                        showed={addDepartmentDialogShowed}
                        setShowed={setAddDepartmentDialogShowed}
                        setLastItemAddedUuid={setLastDepartmentAddedUuid}
                    ></DepartmentDialog>
                </div>
                <div className="admin-headed-content-content">
                    <DataTable<Department>
                        endpoint={DEPARTMENT_LIST_ENDPOINT}
                        columns={dataColumns}
                        reloadUuid={lastDepartmentAddedUuid}
                    />
                </div>
            </div>
        </BaseLayout>
    );
};
