import React, { useEffect, useState } from 'react';
import { CITY_LIST_ENDPOINT, DEPARTMENT_LIST_ENDPOINT, getCityEditEndpoint } from '../../../../api-endpoints';
import './CityList.scss';
import { DataTable } from '../../_common/DataTable/DataTable';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { GridColDef } from '@mui/x-data-grid';
import { City } from '../../../../interfaces/city';
import LocationCity from '@mui/icons-material/LocationCity';
import EditIcon from '@mui/icons-material/Edit';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 } from 'uuid';
import {
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Link,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { handleErrors } from '../../../../utils/http.utils';
import { Department } from '../../../../interfaces/department';
import { ResultWithNbr } from '@stephenprn/typescript-common/lib/interfaces/pagination';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';

const STARRED_INDEXES = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: '', label: 'NO' },
];

export const CityList: React.FC = () => {
    const [reloadUuid, setReloadUuid] = useState<string | undefined>(undefined);
    const [searchText, setSearchText] = useState<string>('');

    const [cityStarredIndexLoading, setCityStarredIndexLoading] = useState<boolean>(false);
    const [cityStarredIndexError, setCityStarredIndexError] = useState<GenericError>(null);

    const setStarredIndexCity = async (city: City, starredIndex: number | null) => {
        if (cityStarredIndexLoading) {
            return;
        }

        setCityStarredIndexLoading(true);
        setCityStarredIndexError(null);

        try {
            await axios.post(
                getCityEditEndpoint(city.uuid),
                {
                    insee: city.insee,
                    name: city.name,
                    funFact: JSON.stringify(city.funFact),
                    activities: JSON.stringify(city.activities),
                    interests: city.interests.join(','),
                    isDestination: city.isDestination,
                    locode: city.locode,
                    starredIndex,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setCityStarredIndexError(err.response?.data);
            setCityStarredIndexLoading(false);
            return;
        }

        setCityStarredIndexLoading(false);
    };
    //Abbeville
    //INSEE	80001
    //Locode FRABB

    const dataColumns: GridColDef[] = [
        {
            field: 'isStarred',
            headerName: 'Starred',
            renderCell: (value: any) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            value={value.row.starredIndex || ''}
                            label="Starred index"
                            onChange={async (e) => {
                                const starredIndex = e.target.value || null;
                                await setStarredIndexCity(value.row, starredIndex);
                                setReloadUuid(v4());
                            }}
                        >
                            {STARRED_INDEXES.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: '',
            headerName: 'Detail',
            flex: 1,
            renderCell: (value: any) => (
                <>
                    <Link href={`/admin/cities/detail/${value.row.uuid}`} component={Button}>
                        <LocationCity />
                        Details
                    </Link>
                    <Link href={`/admin/cities/edit/${value.row.uuid}`} component={Button}>
                        <EditIcon />
                        Edit
                    </Link>
                </>
            ),
        },
        {
            field: 'lol',
            headerName: '',
            renderCell: (value: any) => (
                <Link
                    href={`https://www.sncf-connect.com/app/redirect?redirection_type=SEARCH&origin_transporter_code=${value.row.locode}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    SNCF
                </Link>
            ),
        },
    ];

    // load departments

    const [departments, setDepartments] = useState<Department[]>([]);
    const [departmentsError, setDepartmentsError] = useState<GenericError>(null);
    const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(false);

    const [endpointExtraParams, setEndpointExtraParams] = useState<{
        filterIsDestination?: boolean;
        filterIsStarred?: boolean;
        filterName?: string;
    }>({
        filterIsDestination: true,
    });

    const loadDepartments = async () => {
        if (departmentsLoading) {
            return;
        }

        setDepartmentsLoading(true);
        setDepartmentsError(null);

        let response: any;

        try {
            response = await axios.get<ResultWithNbr<Department>>(DEPARTMENT_LIST_ENDPOINT, {
                params: {
                    pageNbr: 0,
                    nbrResults: 1000,
                },
            });
            setDepartments(response.data.data);

            if (response.data.data.length === 0) {
                setDepartmentsError('No departments found, please add one before adding cities');
            }
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setDepartmentsError(err.response?.data);
        }

        setDepartmentsLoading(false);
    };

    useEffect(() => {
        loadDepartments();
    }, []);

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <ErrorAlert error={cityStarredIndexError} title="City starred" />
                <ErrorAlert error={departmentsError} title="Departments loading" />

                <div className="admin-headed-content-header">
                    <h1>Cities list</h1>
                    <Link href="/admin/cities/add" component={Button} disabled={departments.length === 0}>
                        <AddIcon />
                        Add city
                    </Link>
                </div>
                <div className="admin-headed-content-content">
                    <Card className="filters-card">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={endpointExtraParams.filterIsDestination === true}
                                    indeterminate={endpointExtraParams.filterIsDestination === undefined}
                                    onChange={() => {
                                        if (endpointExtraParams.filterIsDestination === undefined) {
                                            setEndpointExtraParams((prev) => ({
                                                ...prev,
                                                filterIsDestination: true,
                                            }));
                                        } else if (endpointExtraParams.filterIsDestination === true) {
                                            setEndpointExtraParams((prev) => ({
                                                ...prev,
                                                filterIsDestination: false,
                                            }));
                                        } else if (endpointExtraParams.filterIsDestination === false) {
                                            setEndpointExtraParams((prev) => {
                                                const newParams = { ...prev };
                                                delete newParams.filterIsDestination;
                                                return newParams;
                                            });
                                        }
                                    }}
                                />
                            }
                            label="Destination (checked: only show destination, unchecked: only show non-destination, indeterminate: show all)"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={endpointExtraParams.filterIsStarred}
                                    onChange={(e) => {
                                        setEndpointExtraParams((prev) => {
                                            delete prev.filterIsStarred;

                                            return {
                                                ...prev,
                                                ...(e.target.checked ? { filterIsStarred: true } : {}),
                                            };
                                        });
                                    }}
                                />
                            }
                            label="Starred destinations"
                        />

                        <div className="search-city-container">
                            <TextField
                                label="Search cities"
                                variant="outlined"
                                className="search-text-field"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="search"
                                className="search-text-button"
                                onClick={() => {
                                    setEndpointExtraParams((prev) => {
                                        if (searchText === '') {
                                            const newParams = { ...prev };
                                            delete newParams.filterName;
                                            return newParams;
                                        }

                                        return {
                                            ...prev,
                                            filterName: searchText,
                                        };
                                    });
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </div>
                    </Card>
                    <DataTable<City>
                        reloadUuid={reloadUuid}
                        endpoint={CITY_LIST_ENDPOINT}
                        columns={dataColumns}
                        endpointExtraParams={endpointExtraParams}
                    />
                </div>
            </div>
        </BaseLayout>
    );
};
