import React from 'react';
import './CityDetailIllustrationDialog.scss';
import { Dialog, DialogContent } from '@mui/material';
import { CityDetail } from '../../../../../interfaces/city';
import { IFormDialogProps } from '../../../../../interfaces/props/add-item-dialog';

interface CityDetailIllustrationDialogProps extends IFormDialogProps<CityDetail> {
    cityDetail: CityDetail;
}

export const CityDetailIllustrationDialog: React.FC<CityDetailIllustrationDialogProps> = ({
    showed,
    setShowed,
    cityDetail,
}: CityDetailIllustrationDialogProps) => {
    return (
        <Dialog className="dialog" open={showed} onClose={() => setShowed(false)}>
            <DialogContent>
                <h2>Illustration large</h2>
                {cityDetail?.illustrationLargeUrl && (
                    <img
                        src={cityDetail.illustrationLargeUrl}
                        alt="illustration large"
                        onClick={() =>
                            cityDetail.illustrationLargeUrl && window.open(cityDetail.illustrationLargeUrl, '_blank')
                        }
                    />
                )}
                <h2>Illustration small</h2>
                {cityDetail?.illustrationSmallUrl && (
                    <img
                        src={cityDetail?.illustrationSmallUrl}
                        alt="illustration small"
                        onClick={() =>
                            cityDetail.illustrationSmallUrl && window.open(cityDetail.illustrationSmallUrl, '_blank')
                        }
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
