const MAIN_TITLE_INPUT_ID = 'main-title-input';
const BODY_TEXT_INPUT_ID = 'body-text-input';
const BUTTON_TITLE_INPUT_ID = 'button-title-input';
const LINK_INPUT_ID = 'link-input';

interface TryHoppioBlockData {
    mainTitle: string;
    text: string;
    buttonTitle: string;
    link: string;
}

export class TryHoppioBlock {
    data: TryHoppioBlockData;

    constructor({ data }: { data: TryHoppioBlockData }) {
        this.data = data;
    }

    static get toolbox() {
        return {
            title: 'Try hoppio',
            icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0004 17.5C9.74953 17.4997 9.50454 17.4239 9.29731 17.2824C6.22739 15.1984 4.89809 13.7695 4.16489 12.8762C2.60239 10.9719 1.85434 9.0168 1.87543 6.89961C1.90004 4.47344 3.84653 2.5 6.2145 2.5C7.93637 2.5 9.12895 3.46992 9.82348 4.27773C9.84548 4.30307 9.87267 4.32338 9.90319 4.3373C9.93372 4.35122 9.96688 4.35842 10.0004 4.35842C10.034 4.35842 10.0671 4.35122 10.0977 4.3373C10.1282 4.32338 10.1554 4.30307 10.1774 4.27773C10.8719 3.46914 12.0645 2.5 13.7864 2.5C16.1543 2.5 18.1008 4.47344 18.1254 6.9C18.1465 9.01758 17.3977 10.9727 15.836 12.8766C15.1028 13.7699 13.7735 15.1988 10.7036 17.2828C10.4963 17.4241 10.2513 17.4998 10.0004 17.5V17.5Z" fill="#1F1F1F"/></svg>',
        };
    }

    render() {
        const container = document.createElement('div');
        container.classList.add('try-hoppio');

        const bodyText = document.createElement('h3');
        bodyText.innerHTML = 'Try Hoppio block';
        container.appendChild(bodyText);

        const titleInput = document.createElement('input');
        titleInput.id = MAIN_TITLE_INPUT_ID;
        titleInput.type = 'text';
        titleInput.placeholder = 'Main title';
        titleInput.value = this.data.mainTitle || 'Prêt à profiter des bienfaits du voyage ?';
        container.appendChild(titleInput);

        const bodyInput = document.createElement('input');
        bodyInput.id = BODY_TEXT_INPUT_ID;
        bodyInput.type = 'text';
        bodyInput.placeholder = 'Body text';
        bodyInput.value = this.data.text || 'Prenez le train vers des destinations qui vous inspirent.';
        container.appendChild(bodyInput);

        const buttonTitleInput = document.createElement('input');
        buttonTitleInput.id = BUTTON_TITLE_INPUT_ID;
        buttonTitleInput.type = 'text';
        buttonTitleInput.placeholder = 'Button title';
        buttonTitleInput.value = this.data.buttonTitle || 'Essayer Hoppio';
        container.appendChild(buttonTitleInput);

        const linkInput = document.createElement('input');
        linkInput.id = LINK_INPUT_ID;
        linkInput.type = 'text';
        linkInput.placeholder = 'Link';
        linkInput.value = this.data.link || 'https://hoppio.fr/';
        container.appendChild(linkInput);

        const bottomTextWarning = document.createElement('p');
        bottomTextWarning.classList.add('try-hoppio-paragraph');
        bottomTextWarning.classList.add('warning');
        bottomTextWarning.innerHTML = 'Fill all fields aor block will be deleted on save';
        container.appendChild(bottomTextWarning);

        const bottomText = document.createElement('p');
        bottomText.classList.add('try-hoppio-paragraph');
        bottomText.innerHTML = 'Block will be rendered in the blog post';
        container.appendChild(bottomText);

        return container;
    }

    validate(data: TryHoppioBlockData) {
        if (!data.text || !data.mainTitle || !data.buttonTitle || !data.link) {
            throw new Error('Try hoppio block : missing input values');
        }

        return true;
    }

    save(blockContent: any): TryHoppioBlockData {
        const mainTitle = blockContent.querySelector(`#${MAIN_TITLE_INPUT_ID}`)?.value;
        const text = blockContent.querySelector(`#${BODY_TEXT_INPUT_ID}`)?.value;
        const buttonTitle = blockContent.querySelector(`#${BUTTON_TITLE_INPUT_ID}`)?.value;
        const link = blockContent.querySelector(`#${LINK_INPUT_ID}`)?.value;

        const data = {
            mainTitle,
            text,
            buttonTitle,
            link,
        };

        console.log({ data });

        return data;
    }
}
