import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    BLOG_ARTICLE_ADD_ENDPOINT,
    USER_LIST_ENDPOINT,
    getBlogArticleDeleteEndpoint,
    getBlogArticleDetailEndpoint,
    getBlogArticleEditEndpoint,
} from '../../../../api-endpoints';
import { handleErrors } from '../../../../utils/http.utils';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Fab,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import './BlogArticleDetail.scss';
import { createReactEditorJS } from 'react-editor-js';

import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { BlogArticleDetail as IBlogArticleDetail } from '../../../../interfaces/blog-article';
import { EDITOR_JS_TOOLS_BLOG } from '../../../../utils/editor/editor.utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import format from 'date-fns/format';
import CheckIcon from '@mui/icons-material/Check';
import { BlogArticleCategory } from '../../../../enums/blog-article';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from '../../_common/ConfirmDialog/ConfirmDialog';
import { User } from '../../../../interfaces/user';
import { ResultWithNbr } from '@stephenprn/typescript-common/lib/interfaces/pagination';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';

registerLocale('fr', fr);
const DATE_FORMAT = 'dd-MM-yyyy';

const BLOG_ARTICLE_CATEGORY_LABELS = {
    [BlogArticleCategory.TRICK]: 'Astuce',
    [BlogArticleCategory.NEWS]: 'Actualité',
    [BlogArticleCategory.INSPIRATION]: 'Inspiration',
    [BlogArticleCategory.ECOLOGY]: 'Ecologie',
};

const ReactEditorJS = createReactEditorJS();

// https://medium.com/blogging-guide/how-is-medium-article-read-time-calculated-924420338a85
const ESTIMATED_WPM = 265; // reading WPM

const TITLE_MAX_LENGTH = 100;
const SUBTITLE_MAX_LENGTH = 150;

const estimateReadingTime = (
    editorData: any,
    title: string,
    subTitle: string,
    category: keyof typeof BlogArticleCategory,
): number => {
    let readingTimeSec = 0;
    let nbrImages = 0;

    editorData.blocks.forEach((block: any) => {
        let text = '';

        if (block.type === 'header') {
            text = block.data.text;
        }

        if (block.type === 'paragraph') {
            text = block.data.text;
        }

        if (block.type === 'list') {
            text = block.data.items.join(' ');
        }

        if (block.type === 'caption') {
            text = `${block.data.text} ${block.data.caption}`;
        }

        if (block.type === 'image') {
            readingTimeSec += nbrImages > 10 ? 3 : 12 - nbrImages;
            nbrImages++;

            text = block.data.caption;
        }

        if (block.type === 'linkTool') {
            text = `${block.data.meta.description || ''} ${block.data.meta.title || ''}`;
        }

        if (!text) {
            return;
        }

        const nbrWords = text.split(' ').length;
        readingTimeSec += nbrWords * (1 / (ESTIMATED_WPM / 60));
    });

    if (title) {
        readingTimeSec += title.split(' ').length * (1 / (ESTIMATED_WPM / 60));
    }
    if (subTitle) {
        readingTimeSec += subTitle.split(' ').length * (1 / (ESTIMATED_WPM / 60));
    }
    readingTimeSec += BLOG_ARTICLE_CATEGORY_LABELS[category].split(' ').length * (1 / (ESTIMATED_WPM / 60));

    return Math.round(readingTimeSec);
};

export const BlogArticleDetail: React.FC = () => {
    const { blogArticleUuid } = useParams();
    const navigate = useNavigate();
    const editorCore = useRef<any>(null);

    const handleInitializeEditor = useCallback((editorInstance: any) => {
        editorCore.current = editorInstance;
    }, []);

    // users state
    const [users, setUsers] = useState<User[]>([]);
    const [usersLoading, setUsersLoading] = useState<boolean>(false);
    const [usersError, setUsersError] = useState<GenericError>(null);

    // blog article detail state
    const [blogArticleDetail, setBlogArticleDetail] = useState<IBlogArticleDetail>({
        title: '',
        subTitle: '',
        slug: '',
        category: 'TRICK',
        content: {},
        publishDate: '',
        illustrationUrl: '',
        creationDate: '',
        updateDate: '',
        uuid: '',
        author: undefined,
    });
    const [blogArticleDetailLoading, setBlogArticleDetailLoading] = useState<boolean>(false);
    const [blogArticleDetailError, setBlogArticleDetailError] = useState<GenericError>(null);

    const [illustrationFile, setIllustrationFile] = useState<File | null>(null);
    const [illustrationSmallFile, setIllustrationSmallFile] = useState<File | null>(null);

    const [deleteDialogShowed, setDeleteDialogShowed] = useState<boolean>(false);
    const [illustrationShowed, setIllustrationShowed] = useState<boolean>(false);
    const [illustrationSmallShowed, setIllustrationSmallShowed] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<GenericError>(null);

    const loadBlogArticleDetail = async () => {
        if (blogArticleDetailLoading || !blogArticleUuid) {
            return;
        }

        setBlogArticleDetailLoading(true);
        setBlogArticleDetailError(null);

        try {
            const response = await axios.get<IBlogArticleDetail>(getBlogArticleDetailEndpoint(blogArticleUuid));
            setBlogArticleDetail(response.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setBlogArticleDetailError(err.response?.data);
        }

        setBlogArticleDetailLoading(false);
    };

    const loadUsers = async () => {
        if (usersLoading) {
            return;
        }

        setUsersLoading(true);
        setUsersError(null);

        try {
            const response = await axios.get<ResultWithNbr<User>>(USER_LIST_ENDPOINT, {
                params: {
                    pageNbr: 0,
                    nbrResults: 100,
                },
            });
            setUsers(response.data.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setUsersError(err.response?.data);
        }

        setUsersLoading(false);
    };

    useEffect(() => {
        loadBlogArticleDetail();
        loadUsers();
    }, []);

    const saveBlogArticleDetail = async () => {
        if (!editorCore.current) {
            return;
        }

        const editorData = await editorCore.current.save();

        if (loading) {
            return;
        }

        // fields validation

        if (!blogArticleDetail.title) {
            setError('Blog article title is required');
            return;
        }

        // if (!blogArticleDetail.subTitle) {
        //     setError('Blog article sub-title is required');
        //     return;
        // }

        if (!blogArticleDetail.author) {
            setError('Blog article author is required');
            return;
        }

        if (!editorData) {
            setError('Blog article content is required');
            return;
        }

        setLoading(true);
        setError(null);

        let response: any;

        try {
            let endpoint: string;

            if (blogArticleUuid) {
                endpoint = getBlogArticleEditEndpoint(blogArticleUuid);
            } else {
                endpoint = BLOG_ARTICLE_ADD_ENDPOINT;
            }

            response = await axios.post<IBlogArticleDetail>(
                endpoint,
                {
                    title: blogArticleDetail.title,
                    subTitle: blogArticleDetail.subTitle,
                    content: JSON.stringify(editorData),
                    category: blogArticleDetail.category,
                    authorUuid: blogArticleDetail.author.uuid,
                    readingTimeSec: estimateReadingTime(
                        editorData,
                        blogArticleDetail.title,
                        blogArticleDetail.subTitle,
                        blogArticleDetail.category,
                    ),
                    publishDate: blogArticleDetail.publishDate
                        ? format(new Date(blogArticleDetail.publishDate), 'yyyy-MM-dd')
                        : null,
                    ...(illustrationFile ? { illustrationFile } : {}),
                    ...(illustrationSmallFile ? { illustrationSmallFile } : {}),
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );

            setBlogArticleDetail(response.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setError(err.response?.data);
            setLoading(false);
            return;
        }

        setLoading(false);

        if (!blogArticleUuid) {
            navigate(`/admin/blog-articles/detail/${response.data.uuid}`);
        }
    };

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <ErrorAlert error={error} title="Save article" />
                <ErrorAlert error={blogArticleDetailError} title="Get article details" />
                <ErrorAlert error={usersError} title="Get users list" />

                <div className="admin-headed-content-header">
                    <Card className="blog-article-header-card">
                        <CardContent>
                            <TableContainer className="blog-article-header-inputs">
                                <Table
                                    sx={{
                                        td: {
                                            borderBottom: 0,
                                        },
                                    }}
                                >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <div className="blog-article-title-input-delete-container">
                                                    <input
                                                        type="text"
                                                        className="blog-article-title-input"
                                                        placeholder="Title"
                                                        value={blogArticleDetail.title}
                                                        onChange={(e) =>
                                                            setBlogArticleDetail({
                                                                ...blogArticleDetail,
                                                                title: e.target.value,
                                                            })
                                                        }
                                                        maxLength={TITLE_MAX_LENGTH}
                                                    />
                                                    {blogArticleUuid ? (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                className="delete-button"
                                                                onClick={() => setDeleteDialogShowed(true)}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                            <ConfirmDialog
                                                                showed={deleteDialogShowed}
                                                                setShowed={setDeleteDialogShowed}
                                                                bodyText={`Are you sure to delete this blog article?`}
                                                                confirmAction={async () => {
                                                                    setBlogArticleDetailLoading(true);
                                                                    try {
                                                                        await axios.delete(
                                                                            getBlogArticleDeleteEndpoint(
                                                                                blogArticleUuid,
                                                                            ),
                                                                        );
                                                                    } catch (err_: any) {
                                                                        const err = err_ as AxiosError<HttpError>;
                                                                        setBlogArticleDetailError(err.response?.data);
                                                                        setBlogArticleDetailLoading(false);
                                                                        return;
                                                                    }
                                                                    navigate('/admin/blog-articles');
                                                                }}
                                                                confirmText="Delete"
                                                            />
                                                        </>
                                                    ) : null}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <textarea
                                                    className="blog-article-subtitle-input"
                                                    placeholder="Sub-title"
                                                    rows={4}
                                                    value={blogArticleDetail.subTitle}
                                                    onChange={(e) =>
                                                        setBlogArticleDetail({
                                                            ...blogArticleDetail,
                                                            subTitle: e.target.value,
                                                        })
                                                    }
                                                    maxLength={SUBTITLE_MAX_LENGTH}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <strong>Author</strong>
                                            </TableCell>
                                            {users ? (
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <Select
                                                        margin="dense"
                                                        id="blog-article-author"
                                                        label="Author"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={blogArticleDetail.author?.uuid || ''}
                                                        onChange={(e) => {
                                                            const author = users.find((u) => u.uuid == e.target.value);

                                                            setBlogArticleDetail({
                                                                ...blogArticleDetail,
                                                                author,
                                                            });
                                                        }}
                                                    >
                                                        {users.map((user) => (
                                                            <MenuItem key={user.uuid} value={user.uuid}>
                                                                {user.username}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <strong>Category</strong>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <Select
                                                    margin="dense"
                                                    id="blog-article-category"
                                                    label="Category"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={blogArticleDetail.category}
                                                    onChange={(e) =>
                                                        setBlogArticleDetail({
                                                            ...blogArticleDetail,
                                                            category: e.target
                                                                .value as keyof typeof BlogArticleCategory,
                                                        })
                                                    }
                                                >
                                                    {Object.values(BlogArticleCategory).map((category) => (
                                                        <MenuItem key={category} value={category}>
                                                            {BLOG_ARTICLE_CATEGORY_LABELS[category]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        {blogArticleDetail.slug ? (
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Slug</strong>
                                                </TableCell>

                                                <TableCell>{blogArticleDetail.slug}</TableCell>
                                            </TableRow>
                                        ) : null}
                                        {blogArticleDetail.readingTimeSec ? (
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Estimated reading time</strong>
                                                </TableCell>

                                                <TableCell>
                                                    {format(blogArticleDetail.readingTimeSec * 1000, 'mm:ss')}
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                        {blogArticleDetail.slugs ? (
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Slugs</strong>
                                                </TableCell>

                                                <TableCell>
                                                    <ul>
                                                        {blogArticleDetail.slugs.map(({ slug }) => (
                                                            <li key={slug}>
                                                                <a
                                                                    href={`${process.env.REACT_APP_FRONT_PUBLIC_URL}/blog/${slug}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {slug}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <strong>Publication date</strong>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <DatePicker
                                                    selected={
                                                        blogArticleDetail.publishDate
                                                            ? new Date(blogArticleDetail.publishDate)
                                                            : null
                                                    }
                                                    locale="fr"
                                                    dateFormat={DATE_FORMAT}
                                                    onChange={(date: any) =>
                                                        setBlogArticleDetail({
                                                            ...blogArticleDetail,
                                                            publishDate: date,
                                                        })
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <strong>Article illustration (large)</strong>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <div className="blog-article-illustration-cell-inner">
                                                    <input
                                                        type="file"
                                                        id="illustration-file"
                                                        accept="image/*"
                                                        onChange={(e: any) => setIllustrationFile(e.target.files[0])}
                                                    />
                                                    {blogArticleDetail.illustrationUrl ? (
                                                        <>
                                                            <Button
                                                                onClick={() =>
                                                                    setIllustrationShowed(!illustrationShowed)
                                                                }
                                                            >
                                                                {illustrationShowed ? 'Hide' : 'Show'} current
                                                                illustration
                                                            </Button>
                                                            {illustrationShowed ? (
                                                                <img
                                                                    src={blogArticleDetail.illustrationUrl}
                                                                    alt="Illustration"
                                                                />
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <strong>Article illustration (small)</strong>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    verticalAlign: 'top',
                                                }}
                                            >
                                                <div className="blog-article-illustration-cell-inner">
                                                    <input
                                                        type="file"
                                                        id="illustration-small-file"
                                                        accept="image/*"
                                                        onChange={(e: any) =>
                                                            setIllustrationSmallFile(e.target.files[0])
                                                        }
                                                    />
                                                    {blogArticleDetail.illustrationSmallUrl ? (
                                                        <>
                                                            <Button
                                                                onClick={() =>
                                                                    setIllustrationSmallShowed(!illustrationSmallShowed)
                                                                }
                                                            >
                                                                {illustrationSmallShowed ? 'Hide' : 'Show'} current
                                                                small illustration
                                                            </Button>
                                                            {illustrationSmallShowed ? (
                                                                <img
                                                                    src={blogArticleDetail.illustrationSmallUrl}
                                                                    alt="Illustration small"
                                                                />
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </div>
                <div className="admin-headed-content-content">
                    <Card>
                        <CardContent className="blog-article-editor-container">
                            {!blogArticleUuid || (blogArticleUuid && !blogArticleDetailLoading) ? (
                                <ReactEditorJS
                                    onInitialize={handleInitializeEditor}
                                    tools={EDITOR_JS_TOOLS_BLOG}
                                    defaultValue={blogArticleUuid ? blogArticleDetail.content : undefined}
                                />
                            ) : (
                                <CircularProgress />
                            )}
                        </CardContent>
                    </Card>

                    <Fab
                        className="save-button-fab"
                        color="primary"
                        variant="extended"
                        disabled={loading}
                        onClick={saveBlogArticleDetail}
                    >
                        <CheckIcon sx={{ mr: 1 }} />
                        Save
                    </Fab>
                </div>
            </div>
        </BaseLayout>
    );
};
