// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Embed from '@editorjs/embed';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Table from '@editorjs/table';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import List from '@editorjs/list';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Warning from '@editorjs/warning';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Code from '@editorjs/code';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LinkTool from '@editorjs/link';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageTool from '@editorjs/image';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Raw from '@editorjs/raw';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Header from '@editorjs/header';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Quote from '@editorjs/quote';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Marker from '@editorjs/marker';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CheckList from '@editorjs/checklist';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Delimiter from '@editorjs/delimiter';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InlineCode from '@editorjs/inline-code';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SimpleImage from '@editorjs/simple-image';
import { BLOG_ARTICLE_LINK_PREVIEW, BLOG_ARTICLE_UPLOAD_IMAGE } from '../../api-endpoints';
import axios from 'axios';
import { AUTH_ACCESS_TOKEN_STORAGE_KEY } from '../../constants';
import { TryHoppioBlock } from './blocks/try-hoppio';

export const EDITOR_JS_TOOLS = {
    embed: Embed,
    table: Table,
    marker: Marker,
    list: List,
    warning: Warning,
    code: Code,
    linkTool: {
        class: LinkTool,
        config: {
            endpoint: BLOG_ARTICLE_LINK_PREVIEW,
            headers: {
                Authorization: `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN_STORAGE_KEY)}`,
            },
        },
    },
    image: {
        class: ImageTool,
        config: {
            uploader: {
                uploadByFile: async (imageFile: File) => {
                    const response = await axios.post(
                        BLOG_ARTICLE_UPLOAD_IMAGE,
                        {
                            imageFile,
                        },
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    );

                    return response.data;
                },
            },
        },
    },
    raw: Raw,
    header: {
        class: Header,
        config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4],
            defaultLevel: 1,
        },
    },
    quote: Quote,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
};

export const EDITOR_JS_TOOLS_BLOG = {
    ...EDITOR_JS_TOOLS,
    tryHoppio: TryHoppioBlock,
};
