import React, { useState } from 'react';
import './BlogArticleList.scss';
import { GridColDef } from '@mui/x-data-grid';
import { BLOG_ARTICLE_LIST_ENDPOINT } from '../../../../api-endpoints';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { DataTable } from '../../_common/DataTable/DataTable';
import { BlogArticle } from '../../../../interfaces/blog-article';
import { Button, Card, Checkbox, FormControlLabel, Link } from '@mui/material';
import LocationCity from '@mui/icons-material/LocationCity';
import AddIcon from '@mui/icons-material/Add';
import format from 'date-fns/format';

export const BlogArticleList: React.FC = () => {
    const [endpointExtraParams, setEndpointExtraParams] = useState<{
        filterIsPublished?: boolean;
    }>({});

    const dataColumns: GridColDef[] = [
        {
            field: 'publishDate',
            headerName: 'Publish date',
            flex: 1,
            cellClassName: (value: any) => {
                const publishDate = value.row.publishDate ? new Date(value.row.publishDate) : null;
                if (publishDate && publishDate >= new Date()) {
                    return 'red-cell';
                } else if (publishDate && publishDate < new Date()) {
                    return 'green-cell';
                } else if (!publishDate) {
                    return 'yellow-cell';
                }
                return '';
            },
            renderCell: (value: any) => {
                const publishDate = value.row.publishDate ? new Date(value.row.publishDate) : null;
                return publishDate ? format(publishDate, 'dd/MM/yyy') : 'Not specified';
            },
        },
        { field: 'title', headerName: 'Title', flex: 1 },
        {
            field: '',
            headerName: 'Detail',
            flex: 1,
            renderCell: (value: any) => (
                <Link component={Button} href={`/admin/blog-articles/detail/${value.row.uuid}`}>
                    <LocationCity />
                    Go to details
                </Link>
            ),
        },
    ];

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <div className="admin-headed-content-header">
                    <h1>Blog articles list</h1>
                    <Link component={Button} href="/admin/blog-articles/detail/">
                        <AddIcon />
                        Add blog article
                    </Link>
                </div>
                <div className="admin-headed-content-content">
                    <Card className="filters-card">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={endpointExtraParams.filterIsPublished === true}
                                    indeterminate={endpointExtraParams.filterIsPublished === undefined}
                                    onChange={() => {
                                        if (endpointExtraParams.filterIsPublished === undefined) {
                                            setEndpointExtraParams((prev) => ({
                                                ...prev,
                                                filterIsPublished: true,
                                            }));
                                        } else if (endpointExtraParams.filterIsPublished === true) {
                                            setEndpointExtraParams((prev) => ({
                                                ...prev,
                                                filterIsPublished: false,
                                            }));
                                        } else if (endpointExtraParams.filterIsPublished === false) {
                                            setEndpointExtraParams((prev) => {
                                                const newParams = { ...prev };
                                                delete newParams.filterIsPublished;
                                                return newParams;
                                            });
                                        }
                                    }}
                                />
                            }
                            label="Publication date (checked: only show date set, unchecked: only show date not set, indeterminate: show all)"
                        />
                    </Card>
                    <DataTable<BlogArticle>
                        endpoint={BLOG_ARTICLE_LIST_ENDPOINT}
                        columns={dataColumns}
                        endpointExtraParams={endpointExtraParams}
                    />
                </div>
            </div>
        </BaseLayout>
    );
};
