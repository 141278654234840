import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CITY_LIST_ENDPOINT, getDepartmentDetailEndpoint } from '../../../../api-endpoints';
import { handleErrors } from '../../../../utils/http.utils';
import { Department } from '../../../../interfaces/department';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { Button, Link } from '@mui/material';
import { City } from '../../../../interfaces/city';
import { DataTable } from '../../_common/DataTable/DataTable';
import LocationCity from '@mui/icons-material/LocationCity';
import { GridColDef } from '@mui/x-data-grid';
import { DepartmentDialog } from '../dialog/DepartmentDialog';
import './DepartmentDetail.scss';
import EditIcon from '@mui/icons-material/Edit';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';

export const DepartmentDetail: React.FC = () => {
    const { departmentUuid } = useParams();

    if (!departmentUuid) {
        return null;
    }

    const [editDepartmentDialogShowed, setEditDepartmentDialogShowed] = useState(false);

    const [departmentDetail, setDepartmentDetail] = useState<Department | null>(null);
    const [departmentDetailLoading, setDepartmentDetailLoading] = useState<boolean>(false);
    const [departmentDetailError, setDepartmentDetailError] = useState<GenericError>(null);

    const loadDepartmentDetail = async () => {
        if (departmentDetailLoading) {
            return;
        }

        setDepartmentDetailLoading(true);
        setDepartmentDetailError(null);

        let response: any;

        try {
            response = await axios.get<Department>(getDepartmentDetailEndpoint(departmentUuid));
            setDepartmentDetail(response.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setDepartmentDetailError(err.response?.data);
            setDepartmentDetailLoading(false);
            return;
        }

        setDepartmentDetailLoading(false);
    };

    useEffect(() => {
        loadDepartmentDetail();
    }, []);

    const citiesDataColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: '',
            headerName: 'Detail',
            flex: 1,
            renderCell: (value: any) => (
                <Link href={`/admin/cities/detail/${value.row.uuid}`} component={Button}>
                    <LocationCity />
                    Go to details
                </Link>
            ),
        },
    ];

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <div className="admin-headed-content-header">
                    {departmentDetail ? (
                        <div className="admin-headed-content-header-inner">
                            <div className="admin-headed-content-header-top">
                                <h1>
                                    {departmentDetail.name} ({departmentDetail.number})
                                </h1>

                                <Button variant="contained" onClick={() => setEditDepartmentDialogShowed(true)}>
                                    <EditIcon />
                                    Edit department
                                </Button>
                                <DepartmentDialog
                                    showed={editDepartmentDialogShowed}
                                    setShowed={setEditDepartmentDialogShowed}
                                    initialData={departmentDetail}
                                    setDetail={setDepartmentDetail}
                                ></DepartmentDialog>
                            </div>

                            <h2>List of cities</h2>
                        </div>
                    ) : null}
                </div>
                <ErrorAlert error={departmentDetailError} title="Department detail error" />
                <div className="admin-headed-content-content">
                    <DataTable<City>
                        endpoint={CITY_LIST_ENDPOINT}
                        columns={citiesDataColumns}
                        endpointExtraParams={{
                            filterDepartmentUuidIn: [departmentUuid],
                        }}
                    />
                </div>
            </div>
        </BaseLayout>
    );
};
