import { AxiosError } from 'axios';
import { HttpError } from '../interfaces/http';

export const handleErrors = (error: AxiosError<HttpError>) => {
    if (error.status === 401 || error.response?.status === 401) {
        const location = window.location.href.split('/');

        if (location[location.length - 1] !== 'login') {
            const params = new URLSearchParams();

            params.append('tokenExpired', 'true');

            window.location.href = `/login?${params.toString()}`;
        }
    }
};
