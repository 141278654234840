const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BASE_AUTH = `${BASE_URL}/auth`;
export const AUTH_LOGIN_ENDPOINT = `${BASE_AUTH}/login`;
export const AUTH_REGISTER_ENDPOINT = `${BASE_AUTH}/register`;
export const AUTH_CHECK_USERNAME_ENDPOINT = `${BASE_AUTH}/check-username`;
export const AUTH_CHECK_LOGGED_ENDPOINT = `${BASE_AUTH}/check-logged`;

const BASE_CITY = `${BASE_URL}/city`;
export const CITY_LIST_ENDPOINT = `${BASE_CITY}/list`;
export const CITY_ADD_ENDPOINT = `${BASE_CITY}/add`;
export const CITY_SEARCH_ENDPOINT = `${BASE_CITY}/search`;
export const getCityDetailEndpoint = (cityUuid: string) => `${BASE_CITY}/detail/${cityUuid}`;
export const getCityEditEndpoint = (cityUuid: string) => `${BASE_CITY}/edit/${cityUuid}`;
export const getCityDeleteEndpoint = (cityUuid: string) => `${BASE_CITY}/delete/${cityUuid}`;

const BASE_DEPARTMENT = `${BASE_URL}/department`;
export const DEPARTMENT_LIST_ENDPOINT = `${BASE_DEPARTMENT}/list`;
export const DEPARTMENT_ADD_ENDPOINT = `${BASE_DEPARTMENT}/add`;
export const getDepartmentDetailEndpoint = (departmentUuid: string) => `${BASE_DEPARTMENT}/detail/${departmentUuid}`;
export const getDepartmentEditEndpoint = (departmentUuid: string) => `${BASE_DEPARTMENT}/edit/${departmentUuid}`;

const BASE_DESTINATION = `${BASE_URL}/destination`;
export const DESTINATION_LIST_ENDPOINT = `${BASE_DESTINATION}/list`;
export const DESTINATION_DETAIL_ENDPOINT = `${BASE_DESTINATION}/detail`;

const BASE_USER = `${BASE_URL}/user`;
export const USER_LIST_ENDPOINT = `${BASE_USER}/list`;
export const getUserDetailEndpoint = (userUuid: string) => `${BASE_USER}/detail/${userUuid}`;
export const getUserEditEndpoint = (userUuid: string) => `${BASE_USER}/edit/${userUuid}`;
export const getUserDeleteEndpoint = (userUuid: string) => `${BASE_USER}/delete/${userUuid}`;

const BASE_BLOG_ARTICLE = `${BASE_URL}/blog-article`;
export const BLOG_ARTICLE_LIST_ENDPOINT = `${BASE_BLOG_ARTICLE}/list`;
export const BLOG_ARTICLE_ADD_ENDPOINT = `${BASE_BLOG_ARTICLE}/add`;
export const getBlogArticleDetailEndpoint = (blogArticleUuid?: string) =>
    `${BASE_BLOG_ARTICLE}/detail/${blogArticleUuid}`;
export const getBlogArticleEditEndpoint = (blogArticleUuid: string) => `${BASE_BLOG_ARTICLE}/edit/${blogArticleUuid}`;
export const getBlogArticleDeleteEndpoint = (blogArticleUuid: string) =>
    `${BASE_BLOG_ARTICLE}/delete/${blogArticleUuid}`;
export const BLOG_ARTICLE_UPLOAD_IMAGE = `${BASE_BLOG_ARTICLE}/upload-image`;
export const BLOG_ARTICLE_LINK_PREVIEW = `${BASE_BLOG_ARTICLE}/link-preview`;
