import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DEPARTMENT_LIST_ENDPOINT, getCityDeleteEndpoint, getCityDetailEndpoint } from '../../../../api-endpoints';
import './CityDetail.scss';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { CityDetail as ICityDetail } from '../../../../interfaces/city';
import { handleErrors } from '../../../../utils/http.utils';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Department } from '../../../../interfaces/department';
import { ResultWithNbr } from '@stephenprn/typescript-common/lib/interfaces/pagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from '../../_common/ConfirmDialog/ConfirmDialog';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { CityDetailIllustrationDialog } from './illutration-dialog/CityDetailIllustrationDialog';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '../../../../utils/editor/editor.utils';

const ReactEditorJSActivities = createReactEditorJS();
const ReactEditorJSFunFact = createReactEditorJS();

export const CityDetail: React.FC = () => {
    const { cityUuid } = useParams();
    const navigate = useNavigate();

    if (!cityUuid) {
        return null;
    }

    const [deleteCityDialogShowed, setDeleteCityDialogShowed] = useState(false);
    const [cityDetailIllustrationDialogShowed, setCityDetailIllustrationDialogShowed] = useState(false);

    const [cityDetail, setCityDetail] = useState<ICityDetail | null>(null);
    const [cityDetailLoading, setCityDetailLoading] = useState<boolean>(false);
    const [cityDetailError, setCityDetailError] = useState<GenericError>(null);

    const editorCoreActivities = useRef<any>(null);
    const editorCoreFunFact = useRef<any>(null);

    const handleInitializeEditorActivities = useCallback((editorInstance: any) => {
        editorCoreActivities.current = editorInstance;
    }, []);

    const handleInitializeEditorFunFact = useCallback((editorInstance: any) => {
        editorCoreFunFact.current = editorInstance;
    }, []);

    const loadCityDetail = async () => {
        if (cityDetailLoading) {
            return;
        }

        setCityDetailLoading(true);
        setCityDetailError(null);

        let response: any;

        try {
            response = await axios.get<ICityDetail>(getCityDetailEndpoint(cityUuid));
            setCityDetail(response.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setCityDetailError(err.response?.data);
            setCityDetailLoading(false);
            return;
        }

        setCityDetailLoading(false);
    };

    // load departments

    const [departments, setDepartments] = useState<Department[]>([]);
    const [departmentsError, setDepartmentsError] = useState<GenericError>(null);
    const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(false);

    const loadDepartments = async () => {
        if (departmentsLoading) {
            return;
        }

        setDepartmentsLoading(true);
        setDepartmentsError(null);

        let response: any;

        try {
            response = await axios.get<ResultWithNbr<Department>>(DEPARTMENT_LIST_ENDPOINT, {
                params: {
                    pageNbr: 0,
                    nbrResults: 1000,
                },
            });
            setDepartments(response.data.data);

            if (response.data.data.length === 0) {
                setDepartmentsError('No departments found, please add one before adding cities');
            }
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setDepartmentsError(err.response?.data);
        }

        setDepartmentsLoading(false);
    };

    useEffect(() => {
        loadCityDetail();
        loadDepartments();
    }, []);

    const illustrationUrl = cityDetail?.illustrationLargeUrl || cityDetail?.illustrationSmallUrl;

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <ErrorAlert error={cityDetailError} title="Get city details" />
                <ErrorAlert error={departmentsError} title="Departments loading" />

                <div className="admin-headed-content-header">
                    {cityDetail ? <h1>{cityDetail.name}</h1> : null}
                    {cityDetail ? (
                        <div className="admin-headed-content-header-actions">
                            <Button
                                variant="contained"
                                disabled={departments.length === 0}
                                onClick={() => navigate(`/admin/cities/edit/${cityDetail.uuid}`)}
                            >
                                <EditIcon />
                                Edit city
                            </Button>

                            <Button variant="contained" color="error" onClick={() => setDeleteCityDialogShowed(true)}>
                                <DeleteIcon />
                                Delete city
                            </Button>
                            <ConfirmDialog
                                showed={deleteCityDialogShowed}
                                setShowed={setDeleteCityDialogShowed}
                                bodyText={`Are you sure to delete city "${cityDetail.name}"?`}
                                confirmAction={async () => {
                                    try {
                                        await axios.delete(getCityDeleteEndpoint(cityDetail.uuid));
                                    } catch (err_: any) {
                                        const err = err_ as AxiosError<HttpError>;
                                        setCityDetailError(err.response?.data);
                                        setCityDetailLoading(false);
                                        return;
                                    }
                                    navigate('/admin/cities');
                                }}
                                confirmText="Delete"
                            />
                        </div>
                    ) : null}
                </div>
                <div className="admin-headed-content-content">
                    {cityDetail ? (
                        <Card>
                            {illustrationUrl ? (
                                <CardMedia
                                    image={illustrationUrl}
                                    sx={{ height: 250, cursor: 'pointer' }}
                                    onClick={() => setCityDetailIllustrationDialogShowed(true)}
                                />
                            ) : null}

                            {cityDetailIllustrationDialogShowed ? (
                                <CityDetailIllustrationDialog
                                    cityDetail={cityDetail}
                                    showed={cityDetailIllustrationDialogShowed}
                                    setShowed={setCityDetailIllustrationDialogShowed}
                                />
                            ) : null}
                            <CardContent>
                                <h2>General infos</h2>
                                <h3>
                                    <a
                                        href={`${process.env.REACT_APP_FRONT_PUBLIC_URL}/destination/detail/${cityDetail.slug}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        public preview
                                    </a>
                                </h3>
                                <TableContainer>
                                    <Table
                                        sx={{
                                            td: {
                                                borderBottom: 0,
                                            },
                                        }}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>INSEE</strong>
                                                </TableCell>
                                                <TableCell>{cityDetail.administrativeRegion.insee}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Slug</strong>
                                                </TableCell>
                                                <TableCell>{cityDetail.slug}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Locode</strong>
                                                </TableCell>
                                                <TableCell>
                                                    <Link
                                                        href={`https://www.sncf-connect.com/app/redirect?redirection_type=SEARCH&origin_transporter_code=${cityDetail.locode}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {cityDetail.locode}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Department</strong>
                                                </TableCell>
                                                <TableCell>
                                                    <RouterLink to={`/admin/departments/${cityDetail.department.uuid}`}>
                                                        {cityDetail.department.name} ({cityDetail.department.number})
                                                    </RouterLink>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Creation date</strong>
                                                </TableCell>
                                                <TableCell>{cityDetail.creationDate}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Interests</strong>
                                                </TableCell>
                                                <TableCell>
                                                    <ul>
                                                        {cityDetail.interests.map((interest) => (
                                                            <li key={interest}>{interest}</li>
                                                        ))}
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                            {cityDetail ? (
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            verticalAlign: 'top',
                                                        }}
                                                    >
                                                        <strong>Activities</strong>
                                                    </TableCell>
                                                    <TableCell>
                                                        <ReactEditorJSActivities
                                                            readOnly={true}
                                                            holder="holder-activities"
                                                            onInitialize={handleInitializeEditorActivities}
                                                            tools={EDITOR_JS_TOOLS}
                                                            defaultValue={cityDetail.activities}
                                                        >
                                                            <div
                                                                id="holder-activities"
                                                                className="codex-holder-disabled"
                                                            />
                                                        </ReactEditorJSActivities>
                                                    </TableCell>
                                                </TableRow>
                                            ) : null}
                                            {cityDetail ? (
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            verticalAlign: 'top',
                                                        }}
                                                    >
                                                        <strong>Fun fact</strong>
                                                    </TableCell>
                                                    <TableCell>
                                                        <ReactEditorJSFunFact
                                                            readOnly={true}
                                                            holder="holder-funfact"
                                                            onInitialize={handleInitializeEditorFunFact}
                                                            tools={EDITOR_JS_TOOLS}
                                                            defaultValue={cityDetail.funFact}
                                                        >
                                                            <div
                                                                id="holder-funfact"
                                                                className="codex-holder-disabled"
                                                            />
                                                        </ReactEditorJSFunFact>
                                                    </TableCell>
                                                </TableRow>
                                            ) : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h2>Stop points</h2>
                                <TableContainer>
                                    <Table
                                        sx={{
                                            td: {
                                                borderBottom: 0,
                                            },
                                        }}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <Table>
                                                    <TableBody>
                                                        {cityDetail.stopAreas
                                                            .sort((sa1, sa2) => sa1.order - sa2.order)
                                                            .map((stopArea) => (
                                                                <TableRow key={stopArea.name}>
                                                                    <TableCell>
                                                                        <h3>{stopArea.label}</h3>
                                                                        <ul>
                                                                            <li>
                                                                                <strong>order:</strong> {stopArea.order}
                                                                            </li>
                                                                            <li>
                                                                                <strong>uic:</strong> {stopArea.uic}
                                                                            </li>
                                                                            <li>
                                                                                <strong>Coordinates (lat,lon):</strong>{' '}
                                                                                <Link
                                                                                    href={`https://maps.google.com/?q=${stopArea.coordinateLat},${stopArea.coordinateLon}`}
                                                                                    target="_blank"
                                                                                >
                                                                                    {stopArea.coordinateLat},
                                                                                    {stopArea.coordinateLon}
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <strong>Name:</strong> {stopArea.name}
                                                                            </li>
                                                                            <li>
                                                                                <strong>SNCF id:</strong>{' '}
                                                                                {stopArea.sncfId}
                                                                            </li>
                                                                        </ul>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    ) : null}
                </div>
            </div>
        </BaseLayout>
    );
};
