import React, { useState } from 'react';

import './Login.scss';
import Button from '@mui/material/Button';
import { AUTH_LOGIN_ENDPOINT } from '../../api-endpoints';
import { EMAIL_REGEX } from '../../utils/string.utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleErrors } from '../../utils/http.utils';
import { Alert, TextField } from '@mui/material';
import { AUTH_ACCESS_TOKEN_STORAGE_KEY, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../constants';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../interfaces/http';
import { GenericError } from '../../interfaces/error';
import { ErrorAlert } from '../admin/_common/ErrorAlert/ErrorAlert';

export const Login: React.FC = () => {
    const [searchParams] = useSearchParams();
    const tokenExpired = searchParams.get('tokenExpired') === 'true';

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string | null>(null);

    const [error, setError] = useState<GenericError>(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const login = async (email: string, password: string) => {
        if (loading) {
            return;
        }

        setLoading(true);

        // reset errors

        setEmailError(null);
        setPasswordError(null);
        setError(null);

        // validate form

        if (!EMAIL_REGEX.test(email)) {
            setEmailError('Enter a valid email.');
            setLoading(false);
            return;
        }

        if (password.length < PASSWORD_MIN_LENGTH || password.length > PASSWORD_MAX_LENGTH) {
            setPasswordError(
                `Password's length must be between ${PASSWORD_MIN_LENGTH} and ${PASSWORD_MAX_LENGTH} characters`,
            );
            setLoading(false);
            return;
        }

        // call API

        let response: any;

        try {
            response = await axios.post(AUTH_LOGIN_ENDPOINT, {
                email,
                password,
            });
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setError(err.response?.data);
            setLoading(false);
            return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
        window.localStorage.setItem(AUTH_ACCESS_TOKEN_STORAGE_KEY, response.data.accessToken);
        navigate('/admin/cities');
    };

    return (
        <div className="login-container">
            <div className="login-form-container">
                <h1>Hoppio admin interface</h1>

                <ErrorAlert error={error} title="Login error" />
                {tokenExpired ? <Alert severity="error">Your token has expired. Please login again.</Alert> : null}

                <form className="login-form">
                    <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        className="input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError ? <Alert severity="error">{emailError}</Alert> : null}

                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        className="input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError ? <Alert severity="error">{passwordError}</Alert> : null}

                    <Button
                        variant="contained"
                        type="submit"
                        onClick={(e) => {
                            login(email, password);
                            e.preventDefault();
                        }}
                        disabled={loading}
                    >
                        Login
                    </Button>
                </form>
            </div>
        </div>
    );
};
