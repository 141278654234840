import React, { useState } from 'react';
import './UserList.scss';
import { GridColDef } from '@mui/x-data-grid';
import { Button, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import { User } from '../../../../interfaces/user';
import { UserDialog } from '../dialog/UserDialog';
import { USER_LIST_ENDPOINT } from '../../../../api-endpoints';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { DataTable } from '../../_common/DataTable/DataTable';

export const UserList: React.FC = () => {
    const [addUserDialogShowed, setAddUserDialogShowed] = useState(false);
    const [lastUserAddedUuid, setLastUserAddedUuid] = useState<string | undefined>(undefined);

    const dataColumns: GridColDef[] = [
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'username', headerName: 'Username', flex: 1 },
        { field: 'role', headerName: 'Role', flex: 1 },
        {
            field: '',
            headerName: 'Detail',
            flex: 1,
            renderCell: (value: any) => (
                <Link href={`/admin/users/${value.row.uuid}`} component={Button}>
                    <PersonIcon />
                    Go to details
                </Link>
            ),
        },
    ];

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <div className="admin-headed-content-header">
                    <h1>Users list</h1>
                    <Button variant="contained" onClick={() => setAddUserDialogShowed(true)}>
                        <AddIcon />
                        Add user
                    </Button>
                    <UserDialog
                        showed={addUserDialogShowed}
                        setShowed={setAddUserDialogShowed}
                        setLastItemAddedUuid={setLastUserAddedUuid}
                    ></UserDialog>
                </div>
                <div className="admin-headed-content-content">
                    <DataTable<User>
                        endpoint={USER_LIST_ENDPOINT}
                        columns={dataColumns}
                        reloadUuid={lastUserAddedUuid}
                    />
                </div>
            </div>
        </BaseLayout>
    );
};
