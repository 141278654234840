import React, { useEffect, useState } from 'react';
import { getUserDeleteEndpoint, getUserDetailEndpoint } from '../../../../api-endpoints';
import './UserDetail.scss';
import { BaseLayout } from '../../_common/BaseLayout/BaseLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { User as IUserDetail } from '../../../../interfaces/user';
import { handleErrors } from '../../../../utils/http.utils';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { UserDialog } from '../dialog/UserDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from '../../_common/ConfirmDialog/ConfirmDialog';
import axios, { AxiosError } from 'axios';
import { HttpError } from '../../../../interfaces/http';
import { GenericError } from '../../../../interfaces/error';
import { ErrorAlert } from '../../_common/ErrorAlert/ErrorAlert';

export const UserDetail: React.FC = () => {
    const { userUuid } = useParams();
    const navigate = useNavigate();

    if (!userUuid) {
        return null;
    }

    const [editUserDialogShowed, setEditUserDialogShowed] = useState(false);
    const [deleteUserDialogShowed, setDeleteUserDialogShowed] = useState(false);

    const [userDetail, setUserDetail] = useState<IUserDetail | null>(null);
    const [userDetailLoading, setUserDetailLoading] = useState<boolean>(false);
    const [userDetailError, setUserDetailError] = useState<GenericError>(null);

    const loadUserDetail = async () => {
        if (userDetailLoading) {
            return;
        }

        setUserDetailLoading(true);
        setUserDetailError(null);

        let response: any;

        try {
            response = await axios.get<IUserDetail>(getUserDetailEndpoint(userUuid));
            setUserDetail(response.data);
        } catch (err_: any) {
            const err = err_ as AxiosError<HttpError>;
            handleErrors(err);
            setUserDetailError(err.response?.data);
            setUserDetailLoading(false);
            return;
        }

        setUserDetailLoading(false);
    };

    useEffect(() => {
        loadUserDetail();
    }, []);

    return (
        <BaseLayout>
            <div className="admin-headed-content-container">
                <ErrorAlert error={userDetailError} title="Load user detail" />
                <div className="admin-headed-content-header">
                    {userDetail ? <h1>{userDetail.username}</h1> : null}
                    {userDetail ? (
                        <div className="admin-headed-content-header-actions">
                            <Button variant="contained" onClick={() => setEditUserDialogShowed(true)}>
                                <EditIcon />
                                Edit user
                            </Button>
                            <UserDialog
                                showed={editUserDialogShowed}
                                setShowed={setEditUserDialogShowed}
                                initialData={userDetail}
                                setDetail={setUserDetail}
                            />

                            <Button variant="contained" color="error" onClick={() => setDeleteUserDialogShowed(true)}>
                                <DeleteIcon />
                                Delete user
                            </Button>
                            <ConfirmDialog
                                showed={deleteUserDialogShowed}
                                setShowed={setDeleteUserDialogShowed}
                                bodyText={`Are you sure to delete user "${userDetail.username}"?`}
                                confirmAction={async () => {
                                    try {
                                        await axios.delete(getUserDeleteEndpoint(userDetail.uuid));
                                    } catch (err_: any) {
                                        const err = err_ as AxiosError<HttpError>;
                                        setUserDetailError(err.response?.data?.message);
                                        setUserDetailLoading(false);
                                        return;
                                    }
                                    navigate('/admin/users');
                                }}
                                confirmText="Delete"
                            />
                        </div>
                    ) : null}
                </div>
                <div className="admin-headed-content-content">
                    {userDetail ? (
                        <Card>
                            {userDetail.profilePictureUrl ? (
                                <CardMedia
                                    image={userDetail.profilePictureUrl}
                                    sx={{ height: 250, cursor: 'pointer' }}
                                />
                            ) : null}

                            <CardContent>
                                <TableContainer>
                                    <Table
                                        sx={{
                                            td: {
                                                borderBottom: 0,
                                            },
                                        }}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Email</strong>
                                                </TableCell>
                                                <TableCell>{userDetail.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Username</strong>
                                                </TableCell>
                                                <TableCell>{userDetail.username}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong>Role</strong>
                                                </TableCell>
                                                <TableCell>{userDetail.role}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    ) : null}
                </div>
            </div>
        </BaseLayout>
    );
};
